<template>
  <!-- Site Main -->
  <div class="site-main">
    <PageTitle :titleInfo="titleInfo"></PageTitle>

    <!-- Section Services -->
    <div class="section">
      <div class="wrapper-inner">
        <!-- Services List -->
        <div class="widget-services-list">
          <div v-for="(item, index) in services" class="services-item">
            <div class="item-photo">
              <div
                class="photo-big"
                :style="'background-image:url(' + item.image + ');'"></div>
              <!-- <div
                  class="photo-small"
                  data-background="assets/img/photo-services-1-2.jpg"
                ></div>
                <a href="#" class="btn btn-default">LEARN MORE</a> -->
            </div>
            <div class="item-desc">
              <h5>EVENT & OCCASIONS</h5>
              <h2>{{ item.name }}</h2>
              <p>
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
        <!-- Services List End -->
        <h1 style="text-align: center; margin-top: 100px">
          Preferred Caterers
        </h1>
        <p style="font-size: 1.4em; line-height: 30px; text-align: justify">
          At the Fluegel Alumni Center, we are pleased to work with a selection
          of the area’s finest caterers to provide our clients and guests with
          an exceptional experience. The selected caterer will handle all food,
          rental and non-alcoholic beverage needs.
        </p>
        <div
          class="row"
          style="
            text-align: center;
          ">
          <div
            v-for="(item, index) in caterers"
            class="col-md-4"
            style="padding: 20px">
            <div
              class="card"
              style="
                background: white;
                box-shadow: 0px 0px 30px 0px rgba(33, 30, 25, 0.1);
                padding:20px;
                height:400px;
              ">
  <div v-if="item.website">
    <a :href="`https://${item.website}`">
      <img
        v-if="item.logo"
        :src="item.logo"
        :alt="item.name"
        style="width: 100%; margin-bottom: 20px; height: 180px; object-fit: contain;"
      />
    </a>
  </div>
  <div v-else>
    <img
      v-if="item.logo"
      :src="item.logo"
      :alt="item.name"
      style="width: 100%; margin-bottom: 20px; height: 180px; object-fit: contain;"
    />
  </div>
  <div v-if="item.website">
    <a :href="`https://${item.website}`">
      <h2 v-if="item.name">{{ item.name }}</h2>
    </a>
  </div>
  <div v-else>
    <h2 v-if="item.name">{{ item.name }}</h2>
  </div>
              <div
                v-if="item.contact_person"
                style="font-size: 1.2em"
                class="title">
                {{ item.contact_person }}
              </div>
              <div v-if="item.email" style="font-size: 1.2em">
                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
              </div>
              <div v-if="item.phone" style="font-size: 1.2em">
                <a :href="item.tel_link">{{ item.phone }}</a>
              </div>
              <div v-if="item.website" style="font-size: 1.2em">
                <a :href="`https://${item.website}`">{{ item.website }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section Services End -->
  </div>
  <!-- Site Main End -->
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import services from "@/data/services.json";
import caterers from "@/data/caterers.json";
export default {
  name: "Services",
  components: {
    PageTitle,
  },
  inject: ["appInsights"],
  methods: {
    handleClick() {
      this.appInsights.trackEvent({ name: "buttonClicked" });
    },
  },
  data() {
    return {
      titleInfo: {
        subtitle: "Our Services",
        title: "Events & Occasions",
        breadcrumb: "Services",
        description:
          "Interested in learning what we can do for you? Here is a couple examples of events & occasions that you could host at the Fluegel Alumni Center.",
      },
      services,
      caterers,
    };
  },
  page: {
    title: "Services",
    meta: [
      {
        name: "description",
      },
    ],
  },
  created() {
    document.title = "Events & Occasions | Fluegel Alumni Center";
  },
  mounted() {
    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      var window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER MENU --*/
      $(".site-header .header-nav li.sub > a").on("click", function (e) {
        if (window_width < 1200) {
          e.preventDefault();
          var parent = $(this).parent("li"),
            target = $(this).siblings("ul");

          if (parent.hasClass("active")) {
            target.hide();
            parent.removeClass("active");
          } else {
            target.show();
            parent.addClass("active");
          }
        }
      });
      /*-- HEADER MENU END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- MAGNIFIC POPUP --*/
      if ($(".popup-photo").length) {
        $(".popup-photo").magnificPopup({
          type: "image",
        });
      }
      if ($(".popup-gallery").length) {
        $(".popup-gallery").magnificPopup({
          type: "image",
          gallery: {
            enabled: true,
          },
        });
      }
      if ($(".popup-video").length) {
        $(".popup-video").magnificPopup({
          type: "iframe",
        });
      }
      /*-- MAGNIFIC POPUP --*/

      /*-- DATEPICKER --*/
      if ($("input.datepicker").length) {
        var datepicker_width = $("input.datepicker").outerWidth();
        $("input.datepicker").datepicker();
        $("body").append(
          "<style>.ui-datepicker{width:auto; min-width: " +
            datepicker_width +
            "px !important;}</style>"
        );
      }
      /*-- DATEPICKER END --*/

      /*-- FITVIDS --*/
      if ($(".video-full").length) {
        $(".video-full").fitVids();
      }
      /*-- FITVIDS END --*/

      /*-- WIDGET SLIDER --*/
      if ($(".widget-slider").length) {
        $(".widget-slider .widget-carousel").owlCarousel({
          items: 1,
          nav: true,
          navText: ["", ""],
          dots: true,
          autoHeight: true,
          animateOut: "fadeOut",
          animateIn: "fadeIn",
          onInitialized: function () {
            $(".site-header").addClass("header-over");

            if ($(".widget-rooms-carousel.top-over").length) {
              $(".widget-slider").addClass("has-rooms");
            }
          },
        });
      }
      /*-- WIDGET SLIDER END --*/

      /*-- WIDGET GALLERY GRID --*/
      if ($(".widget-gallery-grid").length) {
        $(".widget-gallery-grid .gallery-item a").imagesLoaded(
          { background: true },
          function () {
            // Isotope
            var isotope_photos = $(".widget-gallery-grid .row");

            // Isotope Popups
            isotope_photos.on("arrangeComplete", function () {
              $(".widget-gallery-grid").magnificPopup({
                delegate: ".isotope-item:visible a",
                type: "image",
                gallery: {
                  enabled: true,
                },
              });
            });

            // Isotope Run
            isotope_photos.isotope({ itemSelector: ".isotope-item" });

            // Isotope Filter
            $(".widget-filter-top ul li a").on("click", function (e) {
              e.preventDefault();
              var filter_value = $(this).attr("data-filter");
              isotope_photos.isotope({ filter: filter_value });
              $(".widget-filter-top ul li").removeClass("active");
              $(this).parent("li").addClass("active");
            });
          }
        );
      }
      /*-- WIDGET GALLERY GRID END --*/

      /*-- WIDGET GALLERY CAROUSEL --*/
      if ($(".widget-gallery-carousel").length) {
        $(".widget-gallery-carousel .widget-carousel").owlCarousel({
          center: true,
          loop: true,
          nav: true,
          navText: ["", ""],
          dots: false,
          mouseDrag: false,
          responsive: {
            0: { items: 1 },
            768: { items: 3 },
          },
        });
      }
      /*-- WIDGET GALLERY CAROUSEL END --*/

      /*-- WIDGET ROOMS CAROUSEL --*/
      if ($(".widget-rooms-carousel").length) {
        $(".widget-rooms-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            991: { items: 2 },
            1200: { items: 3 },
          },
        });
      }
      /*-- WIDGET ROOMS CAROUSEL END --*/

      /*-- WIDGET ROOMS DETAIL --*/
      if ($(".widget-rooms-detail").length) {
        var sync1 = $(".widget-rooms-detail .room-slider .owl-carousel"),
          sync2 = $(".widget-rooms-detail .room-thumbnails .owl-carousel");

        sync1
          .owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            dots: false,
            mouseDrag: false,
          })
          .on("changed.owl.carousel", function (e) {
            sync2.trigger("to.owl.carousel", [e.item.index, 250, true]);
          });

        sync2
          .owlCarousel({
            margin: 20,
            dots: false,
            responsive: {
              0: { items: 1 },
              768: { items: 2 },
              991: { items: 3 },
            },
          })
          .on("click", ".owl-item a", function (e) {
            e.preventDefault();
            sync1.trigger("to.owl.carousel", [
              $(this).parent().index(),
              250,
              true,
            ]);
          });
      }
      /*-- WIDGET ROOMS DETAIL END --*/

      /*-- WIDGET BLOG LIST --*/
      if ($(".widget-blog-list").length) {
        $(".widget-blog-list .media-gallery .media-carousel").owlCarousel({
          items: 1,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG LIST END --*/

      /*-- WIDGET BLOG CAROUSEL --*/
      if ($(".widget-blog-carousel").length) {
        $(".widget-blog-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
          onRefreshed: function () {
            var items = $(".widget-blog-carousel .widget-carousel .blog-item"),
              height = 0;

            items.removeAttr("style");
            items.each(function () {
              if ($(this).height() > height) {
                height = $(this).height();
              }
            });
            items.css("height", height);
          },
        });

        $(".widget-blog-carousel .media-gallery .media-carousel").owlCarousel({
          items: 1,
          mouseDrag: false,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG CAROUSEL END --*/

      /*-- WIDGET BLOG SINGLE --*/
      if ($(".widget-blog-single").length) {
        $(".widget-blog-single .media-gallery .media-carousel").owlCarousel({
          items: 1,
          nav: true,
          dots: false,
          navText: ["", ""],
          mouseDrag: false,
          autoplay: true,
        });
      }
      /*-- WIDGET BLOG SINGLE END --*/

      /*-- WIDGET TESTIMONIALS CAROUSEL --*/
      if ($(".widget-testimonials-carousel").length) {
        $(".widget-testimonials-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
          },
        });
      }
      /*-- WIDGET TESTIMONIALS CAROUSEL END --*/

      /*-- WIDGET TEAM CAROUSEL --*/
      if ($(".widget-team-carousel").length) {
        $(".widget-team-carousel .widget-carousel").owlCarousel({
          margin: 50,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
        });
      }
      /*-- WIDGET TEAM CAROUSEL END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/
    })(jQuery);
  },
  props: {},
};
</script>
