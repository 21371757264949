<template>
  <!-- Site Main -->
  <div class="site-main">
    <PageTitle :titleInfo="titleInfo"></PageTitle>

    <!-- Section Rooms -->
    <div class="section">
      <div class="wrapper-inner">
        <!-- Rooms List -->
        <div class="widget-rooms-list">
          <div v-for="(space, index) in spaces" class="rooms-item">
            <div class="item-photo">
              <a
                :href="space.href"
                :style="'background-image:url(' + space.image + ');'"
              >
                ></a
              >
            </div>
            <div class="item-desc">
              <h2>
                <a :href="space.href">{{ space.title }}</a>
              </h2>
              <h5>{{ space.subtitle }}</h5>
              <p>
                {{ space.description }}
              </p>
              <div class="desc-features">
                <ul>
                  <li
                    v-for="(detail, index) in space.details"
                    style="text-transform: uppercase"
                  >
                    <i class="fa fa-check"></i> {{ detail }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="item-price">
              <div class="price-inner">
                <!-- <i class="fa fa-star"></i> -->
                <h3 style="font-size: 1.7em">Interested?</h3>
                <h5>Learn more</h5>
                <a :href="space.contactHref" class="btn">CONTACT US</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Rooms List End -->
      </div>
    </div>
    <!-- Section Rooms End -->
  </div>
  <!-- Site Main End -->
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import globals from "@/data/globals.json";
import spaces from "@/data/spaces.json";
export default {
  name: "Spaces",
  components: {
    PageTitle,
  },
  inject: ["appInsights"],
  methods: { handleClick() { this.appInsights.trackEvent({ name: 'buttonClicked' }) } },
  created(){
        document.title = "Spaces | Fluegel Alumni Center";
    },
  data() {
    return {
      titleInfo: {
        title: "Our Spaces",
        subtitle: "EXPLORE THE OPPORTUNITIES",
        description:
          "Explore the exceptional event spaces that the Fluegel Alumni Center has to offer.  From the grand ballroom, the Reinemund Family Conference Room, to intimate meeting rooms, each space is modern, unique, and equipped with the latest audiovisual equipment.  Your event will be supported by experienced event, catering and audio-visual professionals.  Let us help you create a memorable experience that exceeds your expectations.",
        breadcrumb: "Spaces",
      },
      globals,
      spaces,
    };
  },
  page: {
    title: "Spaces",
    meta: [
      {
        name: "description",
      },
    ],
  },
  mounted() {
    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      var window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER MENU --*/
      $(".site-header .header-nav li.sub > a").on("click", function (e) {
        if (window_width < 1200) {
          e.preventDefault();
          var parent = $(this).parent("li"),
            target = $(this).siblings("ul");

          if (parent.hasClass("active")) {
            target.hide();
            parent.removeClass("active");
          } else {
            target.show();
            parent.addClass("active");
          }
        }
      });
      /*-- HEADER MENU END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- MAGNIFIC POPUP --*/
      if ($(".popup-photo").length) {
        $(".popup-photo").magnificPopup({
          type: "image",
        });
      }
      if ($(".popup-gallery").length) {
        $(".popup-gallery").magnificPopup({
          type: "image",
          gallery: {
            enabled: true,
          },
        });
      }
      if ($(".popup-video").length) {
        $(".popup-video").magnificPopup({
          type: "iframe",
        });
      }
      /*-- MAGNIFIC POPUP --*/

      /*-- DATEPICKER --*/
      if ($("input.datepicker").length) {
        var datepicker_width = $("input.datepicker").outerWidth();
        $("input.datepicker").datepicker();
        $("body").append(
          "<style>.ui-datepicker{width:auto; min-width: " +
            datepicker_width +
            "px !important;}</style>"
        );
      }
      /*-- DATEPICKER END --*/

      /*-- FITVIDS --*/
      if ($(".video-full").length) {
        $(".video-full").fitVids();
      }
      /*-- FITVIDS END --*/

      /*-- WIDGET SLIDER --*/
      if ($(".widget-slider").length) {
        $(".widget-slider .widget-carousel").owlCarousel({
          items: 1,
          nav: true,
          navText: ["", ""],
          dots: true,
          autoHeight: true,
          animateOut: "fadeOut",
          animateIn: "fadeIn",
          onInitialized: function () {
            $(".site-header").addClass("header-over");

            if ($(".widget-rooms-carousel.top-over").length) {
              $(".widget-slider").addClass("has-rooms");
            }
          },
        });
      }
      /*-- WIDGET SLIDER END --*/

      /*-- WIDGET GALLERY GRID --*/
      if ($(".widget-gallery-grid").length) {
        $(".widget-gallery-grid .gallery-item a").imagesLoaded(
          { background: true },
          function () {
            // Isotope
            var isotope_photos = $(".widget-gallery-grid .row");

            // Isotope Popups
            isotope_photos.on("arrangeComplete", function () {
              $(".widget-gallery-grid").magnificPopup({
                delegate: ".isotope-item:visible a",
                type: "image",
                gallery: {
                  enabled: true,
                },
              });
            });

            // Isotope Run
            isotope_photos.isotope({ itemSelector: ".isotope-item" });

            // Isotope Filter
            $(".widget-filter-top ul li a").on("click", function (e) {
              e.preventDefault();
              var filter_value = $(this).attr("data-filter");
              isotope_photos.isotope({ filter: filter_value });
              $(".widget-filter-top ul li").removeClass("active");
              $(this).parent("li").addClass("active");
            });
          }
        );
      }
      /*-- WIDGET GALLERY GRID END --*/

      /*-- WIDGET GALLERY CAROUSEL --*/
      if ($(".widget-gallery-carousel").length) {
        $(".widget-gallery-carousel .widget-carousel").owlCarousel({
          center: true,
          loop: true,
          nav: true,
          navText: ["", ""],
          dots: false,
          mouseDrag: false,
          responsive: {
            0: { items: 1 },
            768: { items: 3 },
          },
        });
      }
      /*-- WIDGET GALLERY CAROUSEL END --*/

      /*-- WIDGET ROOMS CAROUSEL --*/
      if ($(".widget-rooms-carousel").length) {
        $(".widget-rooms-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            991: { items: 2 },
            1200: { items: 3 },
          },
        });
      }
      /*-- WIDGET ROOMS CAROUSEL END --*/

      /*-- WIDGET ROOMS DETAIL --*/
      if ($(".widget-rooms-detail").length) {
        var sync1 = $(".widget-rooms-detail .room-slider .owl-carousel"),
          sync2 = $(".widget-rooms-detail .room-thumbnails .owl-carousel");

        sync1
          .owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            dots: false,
            mouseDrag: false,
          })
          .on("changed.owl.carousel", function (e) {
            sync2.trigger("to.owl.carousel", [e.item.index, 250, true]);
          });

        sync2
          .owlCarousel({
            margin: 20,
            dots: false,
            responsive: {
              0: { items: 1 },
              768: { items: 2 },
              991: { items: 3 },
            },
          })
          .on("click", ".owl-item a", function (e) {
            e.preventDefault();
            sync1.trigger("to.owl.carousel", [
              $(this).parent().index(),
              250,
              true,
            ]);
          });
      }
      /*-- WIDGET ROOMS DETAIL END --*/

      /*-- WIDGET BLOG LIST --*/
      if ($(".widget-blog-list").length) {
        $(".widget-blog-list .media-gallery .media-carousel").owlCarousel({
          items: 1,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG LIST END --*/

      /*-- WIDGET BLOG CAROUSEL --*/
      if ($(".widget-blog-carousel").length) {
        $(".widget-blog-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
          onRefreshed: function () {
            var items = $(".widget-blog-carousel .widget-carousel .blog-item"),
              height = 0;

            items.removeAttr("style");
            items.each(function () {
              if ($(this).height() > height) {
                height = $(this).height();
              }
            });
            items.css("height", height);
          },
        });

        $(".widget-blog-carousel .media-gallery .media-carousel").owlCarousel({
          items: 1,
          mouseDrag: false,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG CAROUSEL END --*/

      /*-- WIDGET BLOG SINGLE --*/
      if ($(".widget-blog-single").length) {
        $(".widget-blog-single .media-gallery .media-carousel").owlCarousel({
          items: 1,
          nav: true,
          dots: false,
          navText: ["", ""],
          mouseDrag: false,
          autoplay: true,
        });
      }
      /*-- WIDGET BLOG SINGLE END --*/

      /*-- WIDGET TESTIMONIALS CAROUSEL --*/
      if ($(".widget-testimonials-carousel").length) {
        $(".widget-testimonials-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
          },
        });
      }
      /*-- WIDGET TESTIMONIALS CAROUSEL END --*/

      /*-- WIDGET FEATURES CAROUSEL --*/
      if ($(".widget-features-carousel").length) {
        $(".widget-features-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 5 },
          },
        });
      }
      /*-- WIDGET FEATURES CAROUSEL END --*/

      /*-- WIDGET TEAM CAROUSEL --*/
      if ($(".widget-team-carousel").length) {
        $(".widget-team-carousel .widget-carousel").owlCarousel({
          margin: 50,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
        });
      }
      /*-- WIDGET TEAM CAROUSEL END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/
    })(jQuery);
  },
  props: {},
};
</script>
