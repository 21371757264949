import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '11ad4e40-07cb-4e9f-944e-f1ced81413b5',
  },
});

appInsights.loadAppInsights();

export default appInsights;

createApp(App).use(router).provide('appInsights', appInsights).mount('#app')
